/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-console */
import { EventEmitter } from 'events';

import { browserLogger } from '@newfront-insurance/dd-rum';
import type { Analytics, Options as SegmentOpts } from '@segment/analytics-next';

import type { EventType, PageEvent, TrackEvent, AliasEvent, IdentifyEvent, GroupEvent, Properties } from '../types';

export interface SegmentClientOptions {
  debug?: boolean;
  enabled?: boolean;
  timeout?: number;
  anonymizeIp?: boolean;
  defaultTrackProperties?: Properties;
}

/**
 * This is the wrapper around the Segment client that allows us to queue events until the library
 * has successfully loaded. If events are tracked before the client has loaded, they will be queued
 * up and triggered once it's ready.
 */
export class SegmentClient {
  public client: Analytics | undefined;

  private readonly logger = browserLogger;

  public emitter = new EventEmitter();

  public defaultTrackProperties = {};

  /**
   * Set the analytics client. This should only be called once.
   * @param analytics
   */
  initialize(analytics: Analytics): void {
    if (this.client) return;
    this.client = analytics;
    this.emitter.emit('initialize');
  }

  /**
   * Set the client options
   */
  setOptions(options: SegmentClientOptions): void {
    this.defaultTrackProperties = options.defaultTrackProperties || {};
  }

  /**
   * Track a page view
   * @param name The name of the page
   * @param properties Any properties describing this page
   * @param options Segment options
   * @param callback
   * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page
   */
  page(event: PageEvent = {}): Promise<void> {
    return new Promise((resolve, reject) => {
      const { name, category, properties } = event;
      if (this.client) {
        this.logger.debug('Page track start', event);
        try {
          this.client.page(category, name, properties, () => {
            this.logger.debug('Page track successful', event);
            resolve();
          });
        } catch (error) {
          this.logger.error('Page track failed');
          reject(error);
        }
      } else {
        this.emitter.once('initialize', () => {
          this.page(event);
        });
        resolve();
      }
    });
  }

  /**
   * Identify the current user. Whenever this is called, all future track events will
   * be associated with this user.
   * @param userId The uuid of the user
   * @param traits Any extra traits about this person to update in Segment
   * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
   */
  identify(event: IdentifyEvent): Promise<void> {
    return new Promise((resolve, reject) => {
      const { userId, traits } = event;
      if (this.client) {
        this.logger.debug('Identify start', event);
        try {
          this.client.identify(userId, traits, () => {
            this.logger.debug('Identify succesful', event);
            resolve();
          });
        } catch (error) {
          this.logger.error('Identify failed');
          reject(error);
        }
      } else {
        this.emitter.once('initialize', () => {
          this.identify(event);
        });
        resolve();
      }
    });
  }

  /**
   * The alias method combines two previously unassociated user identities. Aliasing is generally handled automatically
   * when you identify a user. However, some tools require an explicit alias call.
   *
   * This is an advanced method, but it is required to manage user identities successfully in some of our destinations.
   * Most notably, alias is necessary for properly implementing KISSmetrics and Mixpanel.
   *
   * @param userId The uuid of the user
   * @param traits Any extra traits about this person to update in Segment
   * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#alias
   */
  alias = (event: AliasEvent): Promise<void> => {
    return new Promise((resolve, reject) => {
      const { userId, previousId } = event;
      if (this.client) {
        this.logger.debug('Alias', event);
        try {
          this.client.alias(userId, previousId, () => {
            this.logger.info('Alias succesful', event);
            resolve();
          });
        } catch (error) {
          this.logger.error('Alias failed');
          reject(error);
        }
      } else {
        this.emitter.once('initialize', () => {
          this.alias(event);
        });
      }
    });
  };

  /**
   * Track an event representing an action by the user on the page.
   * @param event The name of the event to send to Segment. Make sure you follow the style guide for event names
   * @param properties Properties associated with the event
   * @param options
   * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
   */
  track = (track: TrackEvent): Promise<void> => {
    const { defaultTrackProperties } = this;

    return new Promise((resolve, reject) => {
      const { event, properties } = track;
      if (this.client) {
        this.logger.debug('Track started', track);
        try {
          this.client.track(
            event,
            {
              ...defaultTrackProperties,
              ...properties,
            },
            () => {
              this.logger.debug('Track successful', track);
              resolve();
            },
          );
        } catch (e) {
          this.logger.error('Track failed');
          reject(e);
        }
      } else {
        this.emitter.once('initialize', () => {
          this.track(track);
        });
        resolve();
      }
    });
  };

  /**
   * This will associate the current user with this group
   * @param name The name of the group to associate the current user with
   * @param traits Traits about this group
   * @param options
   * @param callback
   * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#group
   */
  group(event: GroupEvent): Promise<void> {
    return new Promise((resolve, reject) => {
      const { groupId, traits } = event;
      if (this.client) {
        this.logger.debug('Group started', event);
        try {
          this.client.group(groupId, traits, () => {
            this.logger.debug('Group succesful', event);
            resolve();
          });
        } catch (error) {
          this.logger.error('Group failed');
          reject(error);
        }
      } else {
        this.emitter.once('initialize', () => {
          this.group(event);
        });
      }
    });
  }

  /**
   * The ready method allows you to pass in a callback that is called once all enabled destinations load, and once
   * analytics.js finishes initializing.
   * @param callback
   * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#ready
   */
  ready(callback?: (analytics: Analytics) => void): Promise<void> {
    return new Promise((resolve) => {
      if (this.client) {
        this.client.ready(() => {
          if (callback && this.client) callback(this.client);
          resolve();
        });
      } else {
        this.emitter.once('initialize', () => {
          this.ready(callback);
        });
      }
    });
  }

  /**
   * The global analytics object emits events whenever you call alias, group, identify, track or page.
   * @param method
   * @param callback
   */
  on(method: EventType, callback: (event: string, properties?: Properties, options?: SegmentOpts) => void): void {
    if (this.client) {
      this.client.on(method, callback);
    } else {
      this.emitter.once('initialize', () => {
        this.on(method, callback);
      });
    }
  }

  /**
   * Set the ID for the anonymous
   * @param id
   */
  setAnonymousId(id: string): Promise<void> {
    return new Promise((resolve) => {
      if (this.client) {
        this.client.setAnonymousId(id);
      } else {
        this.emitter.once('initialize', () => {
          this.setAnonymousId(id);
          resolve();
        });
      }
    });
  }
}
