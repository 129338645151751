import type { OidcConfiguration } from '@axa-fr/react-oidc';
import {
  OidcProvider,
  OidcSecure,
  OidcUserStatus,
  useOidc,
  useOidcAccessToken,
  useOidcIdToken,
  useOidcUser,
} from '@axa-fr/react-oidc';
import { browserLogger } from '@newfront-insurance/dd-rum';
import type { DecodedIdentityJwt, DecodedJwt } from '@newfront-insurance/next-auth-api-types';
import type { Provider } from '@newfront-insurance/react-provision';
import { createProvider, useProvider } from '@newfront-insurance/react-provision';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';

import { SessionTimeoutErrorBoundary } from '../boundaries/session-timeout-error-boundary';
import type { KeycloakAuthClient } from '../clients/keycloak';
import { getKeycloakAuthClient } from '../clients/keycloak';
import { SessionStoragePersistor } from '../clients/persistors/session-storage';
import { IDPHint } from '../constants';
import { isTokenExpired, parseTokenPayload } from '../internal/helpers/jwt';
import type {
  Account,
  AuthApiConfig,
  AuthClient,
  AuthClientState,
  AuthProviderContext,
  AuthState,
  AuthSwapProviderContext,
  SwapContext,
  UserProfile,
} from '../types';

export enum AuthProviderType {
  OIDC_CLIENT = 'OIDC_CLIENT',
  KEYCLOAK_JS = 'KEYCLOAK_JS',
}

interface Props {
  idpHint?: IDPHint;
  redirectUri?: string;
  authApiConfig: AuthApiConfig;
  onSessionLost?: () => void;
}
interface SwapProviderOptions {
  authProvider: Provider<AuthProviderContext>;
  clientId: string;
  isEnabled?: boolean;
}

/**
 * Subscribe to the auth state on an AuthClient
 * @param client AuthClient
 * @returns AuthState
 */
export function useAuthState(client: AuthClient): AuthState {
  const [authState, setAuthState] = useState<AuthState>(() => client.getAuthState());

  useEffect(() => {
    const unsub = client.subscribe((newState) => setAuthState(newState));
    return () => {
      unsub();
    };
  }, [client]);

  return authState;
}

/**
 * Get the user profile from the auth state on the auth client.
 * @param tokens The state from the AuthClient
 * @returns UserProfile
 */
export function useUserProfile(tokens: Pick<AuthClientState, 'idToken' | 'token'>): UserProfile | undefined {
  const { idToken, token } = tokens;

  return useMemo<UserProfile | undefined>(() => {
    if (!token || !idToken) {
      return undefined;
    }
    const parsedToken = parseTokenPayload<DecodedJwt & { newfront_accounts: Account[]; accountUuids?: string[] }>(
      token,
    );
    const parsedIdToken = parseTokenPayload<DecodedIdentityJwt>(idToken);
    const scopes = parsedToken.scope?.split(' ') ?? [];

    return {
      uuid: parsedIdToken.userId,
      email: parsedIdToken.email,
      profilePic: parsedIdToken.profilePic,
      roles: scopes,
      scopes,
      firstName: parsedIdToken.given_name,
      lastName: parsedIdToken.family_name,
      accounts: parsedToken.newfront_accounts ?? [],
      accountUuid: parsedToken.accountUuids?.[0],
    };
  }, [idToken, token]);
}

/**
 * Login using the client.
 * @param client AuthClient
 */
export function useAuthClientLogin(client: AuthClient): void {
  const [error, setError] = useState<Error>();
  const { isReady, isLoggedIn } = useAuthState(client);

  // Start the auth client
  useEffect(() => {
    if (!isReady) {
      client.start().catch((e) => {
        setError(e);
      });
    }
  }, [isReady, client]);

  // Login when ready
  useEffect(() => {
    if (isReady && !isLoggedIn) {
      client.login().catch((e) => {
        setError(e);
      });
    }
  }, [isLoggedIn, isReady, client]);

  if (error) {
    throw error;
  }
}

/**
 * Returns a function that can be used to get an updated auth token that can be used
 * to make requests. This should be called before every request.
 * @param client AuthClient
 * @returns
 */
export function useAccessTokenCallback(client: AuthClient): () => Promise<string | undefined> {
  return useCallback(async () => {
    const tokens = await client.updateTokens();

    return tokens?.token;
  }, [client]);
}

/**
 * Returns an async callback that will swap the current token for a another token
 * for a different client id.
 */
function useSwapTokenProvider(
  authClient: AuthClient,
  clientId: string,
  isEnabled = true,
  initialSwapContext: SwapContext | undefined = undefined,
): AuthSwapProviderContext {
  const authState = useAuthState(authClient);
  const [token, setToken] = useState<string>();
  const [scopes, setScopes] = useState<string[]>();
  const [swapContext, setSwapContext] = useState<SwapContext | undefined>(initialSwapContext);
  const userDetails = useUserProfile({ idToken: authState.idToken, token });

  // Use refs to store mutable state that shouldn't trigger rerenders
  const stateRef = useRef({
    token,
    swapContext,
    pendingSwap: undefined as Promise<string | undefined> | undefined,
    isSwapping: false,
  });

  // Keep ref up to date without triggering rerenders
  useEffect(() => {
    stateRef.current.token = token;
    stateRef.current.swapContext = swapContext;
  }, [token, swapContext]);

  // Create a stable request swap token function that always uses latest state
  const requestSwapToken = useCallback(async () => {
    const state = stateRef.current;

    // Check auth state at execution time
    const currentAuthState = authClient.getAuthState();
    if (!currentAuthState.isLoggedIn || !isEnabled || !state.swapContext?.newfrontAccountUuid) {
      browserLogger.info('Skipping token swap - prerequisites not met', {
        isLoggedIn: currentAuthState.isLoggedIn,
        isEnabled,
        hasContext: !!state.swapContext?.newfrontAccountUuid,
      });
      return;
    }

    if (state.isSwapping) {
      browserLogger.info('Swap already in progress, skipping');
      return;
    }

    try {
      state.isSwapping = true;
      const { token: newToken } = await authClient.swap(clientId, state.swapContext);
      setToken(newToken);
      return newToken;
    } catch (error) {
      browserLogger.error('Token swap failed:', { error });
      if (error instanceof Error) {
        if (
          error.message.includes('401') ||
          error.message.includes('403') ||
          error.message.includes('invalid_token') ||
          error.message.includes('Token invalid')
        ) {
          setToken(undefined);
        }
      }
      throw error;
    } finally {
      state.isSwapping = false;
      state.pendingSwap = undefined;
    }
  }, [authClient, clientId, isEnabled]); // Only depend on stable values

  // Create a stable getToken function that always uses latest state
  const getSwappedAccessToken = useCallback(async () => {
    const state = stateRef.current;

    // If there's no context, return undefined immediately
    if (!state.swapContext) {
      return undefined;
    }

    // If there's a pending swap, return that
    if (state.pendingSwap) {
      return state.pendingSwap;
    }

    // If we have a valid token, return it
    if (state.token && !isTokenExpired(state.token)) {
      return state.token;
    }

    // Start new swap request
    try {
      state.pendingSwap = requestSwapToken();
      const result = await state.pendingSwap;
      return result;
    } catch (error) {
      state.pendingSwap = undefined;
      throw error;
    }
  }, [requestSwapToken]); // Only depend on requestSwapToken

  // Effect to handle swap context changes
  useEffect(() => {
    if (!swapContext?.newfrontAccountUuid) return;

    const currentAuthState = authClient.getAuthState();
    if (!currentAuthState.isLoggedIn) {
      browserLogger.info('Not logged in, skipping token swap');
      return;
    }

    setScopes(undefined);
    setToken(undefined);
    requestSwapToken();
  }, [swapContext, authClient, requestSwapToken]);

  return {
    getSwappedAccessToken,
    getToken: getSwappedAccessToken,
    scopes,
    swapContext,
    setSwapContext,
    userDetails,
  };
}

/**
 * Create the KeycloakAuthClient that will be used to manage the JWT token.
 */
function useKeycloakAuthClient(options: Props): KeycloakAuthClient {
  const { authApiConfig, idpHint, redirectUri } = options;
  const { clientId, realm, url } = authApiConfig;

  return useMemo(() => {
    return getKeycloakAuthClient({
      config: {
        clientId,
        realm,
        url,
      },
      initOptions: {
        pkceMethod: 'S256',
      },
      loginOptions: {
        idpHint,
        redirectUri,
      },
      tokenPersistor: new SessionStoragePersistor(`kcToken-${realm}-${clientId}`),
    });
  }, [clientId, idpHint, realm, redirectUri, url]);
}

export interface AuthProviderProps {
  providerType?: AuthProviderType;
  children: ReactNode;
}

export const createAuthProvider = (props: Props): Provider<AuthProviderContext, AuthProviderProps> => {
  const { authApiConfig } = props;
  const { clientId, realm } = authApiConfig;

  return createProvider<AuthProviderContext, AuthProviderProps>(
    ({ providerType }) => {
      if (providerType === AuthProviderType.OIDC_CLIENT) {
        return useOIDCAuthProvider();
      }

      const client = useKeycloakAuthClient(props);
      const authState = useAuthState(client);
      const userDetails = useUserProfile(authState);
      const logout = useCallback(async (redirectUrl: string) => client.logout(redirectUrl), [client]);
      const getAccessTokenCallback = useAccessTokenCallback(client);

      return {
        getAccessTokenCallback,
        getToken: getAccessTokenCallback,
        logout,
        userDetails,
        authState,
        client,
      } as AuthProviderContext;
    },
    {
      name: `AuthProvider-${realm}-${clientId}`,
      wrapper: ({ children, providerType }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const router = useRouter();
        if (providerType === AuthProviderType.OIDC_CLIENT) {
          const configuration: OidcConfiguration = {
            client_id: authApiConfig.clientId,
            redirect_uri: `${window.location.origin}/#authentication/callback`,
            silent_redirect_uri: `${window.location.origin}/#authentication/silent-callback`,
            monitor_session: true,
            scope: 'openid profile email offline_access',
            authority: `${authApiConfig.url}/realms/${authApiConfig.realm}`,
            refresh_time_before_tokens_expiration_in_second: 30,
            storage: localStorage,
            extras:
              props.idpHint === IDPHint.GSUITE
                ? {
                    kc_idp_hint: 'gsuite',
                  }
                : {},
          };

          const withCustomHistory = () => {
            return {
              replaceState: (url: string) => {
                router
                  .replace({
                    pathname: url,
                  })
                  .then(() => {
                    // eslint-disable-next-line no-undef
                    window.dispatchEvent(new Event('popstate'));
                  });
              },
            };
          };

          return (
            <OidcProvider
              onEvent={(_config, eventName, data) => {
                if (eventName.includes('error')) {
                  console.error('[Newfront Auth Error]', eventName, data);
                }
              }}
              withCustomHistory={withCustomHistory}
              configuration={configuration}
              onSessionLost={() => {
                console.error('[Newfront Auth Error]', 'Session lost');
              }}
              authenticatingComponent={() => null}
              authenticatingErrorComponent={() => null}
              loadingComponent={() => null}
              callbackSuccessComponent={() => null}
              sessionLostComponent={() => {
                return <SessionTimeoutErrorBoundary />;
              }}
            >
              <OidcSecure>{children}</OidcSecure>
            </OidcProvider>
          );
        }

        return children as JSX.Element;
      },
    },
  );
};

export interface AuthSwapProviderOptions {
  initialSwapContext?: SwapContext;
}

export function createAuthSwapProvider(
  options: SwapProviderOptions,
): Provider<AuthSwapProviderContext, AuthSwapProviderOptions> {
  const { authProvider, clientId, isEnabled = true } = options;

  return createProvider((opts: AuthSwapProviderOptions) => {
    const { initialSwapContext } = opts;
    const { client } = useProvider(authProvider);
    return useSwapTokenProvider(client, clientId, isEnabled, initialSwapContext);
  });
}

const useOIDCAuthProvider = (): AuthProviderContext => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const { idToken, idTokenPayload } = useOidcIdToken();
  const { logout, login } = useOidc();
  const authState = useOIDCAuthStateFromUser();

  return {
    getAccessTokenCallback: () => accessToken,
    getToken: () => accessToken,
    userDetails: {
      accounts: [],
      email: idTokenPayload.email as string,
      firstName: idTokenPayload.given_name as string,
      lastName: idTokenPayload.family_name as string,
      roles: accessTokenPayload?.scope?.split(' ') ?? [],
      scopes: accessTokenPayload?.scope?.split(' ') ?? [],
      uuid: accessTokenPayload.userId,
      profilePic: idTokenPayload?.profilePic,
    },
    authState,
    logout: async (redirectUri: string) => {
      await logout(redirectUri);
    },
    client: {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      login: async () => {
        await login();
      },
      // Not necessary when using OIDC
      start: async () => {
        return true;
      },
      // Not necessary when using OIDC
      stop: () => {
        return true;
      },
      // Not necessary when using OIDC
      subscribe: () => {
        return () => {
          return true;
        };
      },
      // Not necessary when using OIDC
      updateTokens: async () => ({
        idToken: '',
        token: '',
      }),
      getAuthState: () => ({
        state: 'LOGGED_IN',
        isLoggedIn: true,
        isReady: true,
        idToken,
        token: accessToken,
      }),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      logout: async (redirectUri: string) => {
        await logout(redirectUri);
      },
      // TODO: to be implemented
      swap: async () => {
        return {
          token: '',
          refreshToken: '',
        };
      },
      getDirectGrantLoginToken: async () => {
        return undefined;
      },
    },
  };
};

export class SessionTimeoutError extends Error {}

function useOIDCAuthStateFromUser(): AuthState {
  const { oidcUserLoadingState } = useOidcUser();
  const { accessToken } = useOidcAccessToken();
  const { idToken } = useOidcIdToken();

  if (oidcUserLoadingState === OidcUserStatus.Loaded) {
    return {
      state: 'LOGGED_IN',
      idToken,
      isLoggedIn: true,
      token: accessToken,
      isReady: true,
    };
  }

  return {
    state: 'NOT_READY',
    idToken,
    isLoggedIn: false,
    isReady: false,
    token: accessToken,
  };
}
