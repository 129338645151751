import { useNotifications } from '@newfront-insurance/next-notifications';
import { useCookie, useFavicon, useLocalStorage, useTitle } from 'react-use';

export type FaviconEnvironment = 'development' | 'production' | 'staging';

/**
 * @deprecated Please use useNewfrontLogoFavicon instead
 * Render the Newfront favicon based on the environment
 * @param environment
 */
export function useNewfrontFavicon(environment?: FaviconEnvironment): void {
  useFavicon(
    environment
      ? `https://legacy.newfront.com/static/favicon-${environment}.png`
      : `https://legacy.newfront.com/static/favicon-production.png`,
  );
}

/**
 * @deprecated Please consult with the frontend team about the modern approach to favicons
 */
// TODO(ACC-1335):Remove favicon from accounts app
export function useNewfrontLogoFavicon(): void {
  useFavicon(`https://dash.newfront-staging.com/favicon.png`);
}

/**
 * @deprecated Please use next/head <Head> component instead
 */
export function usePageTitle(title?: string): void {
  return useTitle(title ? `${title} | Newfront Insurance` : 'Newfront Insurance');
}

/**
 * Load the current CSRF token for this session.
 */
export function useXsrfToken(): string | null {
  const [token] = useCookie('XSRF-TOKEN');
  return token;
}

/**
 * Extends the useLocalStorage hook to prefix the key with a namespace
 */
const PREFIX_LOCAL_STORAGE = '@newfront/';
export function useNewfrontLocalStorage<T>(...params: Parameters<typeof useLocalStorage<T>>) {
  const [key, ...rest] = params;
  return useLocalStorage<T>(`${PREFIX_LOCAL_STORAGE}${key}`, ...rest);
}

interface CopyToClipboardOptions {
  textToCopy: string;
  /**
   * Supports copying rich text with HTML formatting eg. <b>hello</b><br><br>line
   * Do also pass non-rich text as `textToCopy` as a fallback
   */
  htmlToCopy?: string;
  successMessage?: string;
  errorMessage?: string;
}

/**
 * Hook to copy a value to the clipboard
 * Notifies on success or failure
 */
export function useCopyToClipboard() {
  const notifications = useNotifications();

  return (options: CopyToClipboardOptions) => {
    const { textToCopy, htmlToCopy, successMessage, errorMessage } = options;
    const successMsg = successMessage ?? 'Copied to clipboard';
    const errorMsg = errorMessage ?? 'Failed to copy to clipboard';

    const htmlBlob = htmlToCopy ? new Blob([htmlToCopy], { type: 'text/html' }) : undefined;
    const textBlob = new Blob([textToCopy], { type: 'text/plain' });
    const clipboardData = [new ClipboardItem({ 'text/plain': textBlob, ...(htmlBlob && { 'text/html': htmlBlob }) })];

    // Use the native clipboard API
    navigator.clipboard
      .write(clipboardData)
      .then(() => {
        // Show success notification on successful copy
        notifications.add({
          title: successMsg,
          type: 'success',
        });
      })
      .catch((error) => {
        // Show error notification if copy fails
        notifications.add({
          title: `${errorMsg}: ${error.message || 'Unknown error'}`,
          type: 'error',
        });
      });
  };
}
