/* eslint-disable no-process-env */
import { AdminTRPCProvider } from '@newfront-insurance/admin-ui';
import { LogoSpinner } from '@newfront-insurance/core-ui';
import { TooltipProvider } from '@newfront-insurance/core-ui/v2';
import { HTTPError, isTRPCClientError } from '@newfront-insurance/data-layer-client';
import { DatadogRumWrapper } from '@newfront-insurance/dd-rum';
import { NewfrontApolloProvider } from '@newfront-insurance/graphql-frontend';
import { SSRBoundary } from '@newfront-insurance/next-auth';
import { NotificationProvider } from '@newfront-insurance/next-notifications';
import { RouterProvider } from '@newfront-insurance/next-router-provider';
import { createContainer } from '@newfront-insurance/react-provision';
import type { QueryObserverOptions } from '@tanstack/react-query';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { PropsWithChildren } from 'react';
import { Suspense } from 'react';
import * as React from 'react';

import { AnalyticsProvider } from './analytics';
import { FeatureFlagProvider } from './feature-flag';
import { useIsMobile } from '../helpers/use-is-mobile';

import { AuthProvider, AuthSwapProvider } from '@/client/providers/auth';
import { ResponsiveProvider } from '@/client/providers/responsive';
import { AuthSwrProvider } from '@/client/providers/swr';
import { RootTRPCProvider, SwapTRPCClientProvider, SwapTRPCProvider, TRPCProvider } from '@/client/providers/trpc';
import { getConfig } from '@/config';

const RETRYABLE_STATUS_CODES = [418, 502, 503, 504];
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError: (_error, query) => {
        return !query.state.data && (query.options as QueryObserverOptions).suspense === true;
      },
      retry(failureCount, error) {
        if (failureCount >= 3) {
          return false;
        }

        if (isTRPCClientError(error) && error.cause instanceof HTTPError) {
          const statusCode = error.cause.response.status;

          return RETRYABLE_STATUS_CODES.includes(statusCode);
        }

        return true;
      },
    },
  },
});

const config = getConfig();

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

function ClientNotificationProvider({ children }: PropsWithChildren<unknown>): JSX.Element {
  const { isMobile } = useIsMobile();

  return <NotificationProvider offsetX={isMobile ? undefined : 160}>{children}</NotificationProvider>;
}
/**
 * These are the common service providers used everywhere that don't require user state.
 */
const BasicProviders = createContainer([
  RouterProvider,
  ResponsiveProvider,
  ClientNotificationProvider,
  ({ children }) => <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>,
]);

/**
 * These are the providers that provide information about the current user.
 */
const IdentityProviders = createContainer([AuthProvider, AuthSwapProvider]);

/**
 * These providers give us access to TRPC clients and depend on the identity providers.
 */
const TRPCProviders = createContainer([RootTRPCProvider, SwapTRPCClientProvider, SwapTRPCProvider, TRPCProvider]);

/**
 * These providers give us access to team UI libraries.
 */
const TeamLibraryProviders = createContainer([
  AuthSwrProvider,
  ({ children }) => (
    <AdminTRPCProvider authProvider={AuthSwapProvider} basePath="/api/admin-ui/trpc">
      {children}
    </AdminTRPCProvider>
  ),
]);

/**
 * These providers will track some information about the current user.
 */
const TrackingProviders = createContainer([AnalyticsProvider, FeatureFlagProvider]);

/**
 * The application can be wrapped in this component to give it access to all providers.
 */
export function AllProviders({ children }: Props): JSX.Element {
  return (
    <SSRBoundary>
      <Suspense fallback={<LogoSpinner />}>
        <BasicProviders>
          <IdentityProviders>
            <DatadogRumWrapper
              appName="client-dash-app"
              applicationId={config.DATADOG.RUM.CLIENT_DASH.APPLICATION_ID}
              clientToken={config.DATADOG.RUM.CLIENT_DASH.CLIENT_TOKEN}
              authProvider={AuthProvider}
            >
              <TrackingProviders>
                <NewfrontApolloProvider authProvider={AuthSwapProvider} config={config}>
                  <TRPCProviders>
                    <TeamLibraryProviders>
                      <TooltipProvider>{children}</TooltipProvider>
                    </TeamLibraryProviders>
                  </TRPCProviders>
                </NewfrontApolloProvider>
              </TrackingProviders>
            </DatadogRumWrapper>
          </IdentityProviders>
        </BasicProviders>
      </Suspense>
    </SSRBoundary>
  );
}
