/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNotifications } from '@newfront-insurance/next-notifications';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
  keepPreviousData,
} from '@tanstack/react-query';
import type { TRPCClient } from '@trpc/client';
import type { AnyRouter } from '@trpc/server';
import { useCallback } from 'react';

import { getArgs, getCacheKey } from './internals/utils';
import type { TRPCClientHooks } from './types';
import { getPreferredErrorMessage } from '../errors/utils';

/**
 * Thin wrapper over react-query to make our react-query usage type-safe within TRPC.
 *
 * react-query can always be used without these hooks.
 *
 * @param client TRPCClient
 * @returns TRPCClientHooks
 */
export function useClientQueryHooks<Router extends AnyRouter>(client: TRPCClient<Router>): TRPCClientHooks<Router> {
  const queryClient = useQueryClient();

  const clientQueryHooks: Omit<TRPCClientHooks<Router>, 'useErrorHandledMutation'> = {
    useQuery: (pathAndInput, opts) => {
      const {
        // Skip these options as they're no longer supported as valid options
        keepPreviousData: isKeepPreviousData,
        // Keep the rest of the options
        ...rest
      } = opts ?? {};

      const useQueryOptions = {
        ...rest,
        ...(isKeepPreviousData ? { placeholderData: keepPreviousData } : {}),
      };

      const queryOptions = {
        queryKey: getCacheKey(pathAndInput) as any,
        queryFn: () => (client as any).query(...getArgs(pathAndInput, useQueryOptions)) as any,
        ...useQueryOptions,
      };
      return useQuery(queryOptions);
    },
    useSuspenseQuery: (pathAndInput, opts) => {
      return useSuspenseQuery({
        queryKey: getCacheKey(pathAndInput) as any,
        queryFn: () => (client as any).query(...getArgs(pathAndInput, opts)) as any,
        ...opts,
      });
    },
    useInfiniteQuery: (pathAndInput, opts) => {
      const [path, input] = pathAndInput;
      return useInfiniteQuery({
        queryKey: getCacheKey(pathAndInput) as any,
        queryFn: ({ pageParam }) => {
          const actualInput = { ...((input as any) ?? {}), cursor: pageParam };

          return (client as any).query(...getArgs([path, actualInput], opts));
        },
        ...opts,
      });
    },
    useMutation: (path, opts) => {
      const mutationResponse = useMutation({ mutationFn: (input) => (client.mutation as any)(path, input), ...opts });
      return { ...mutationResponse, isLoading: mutationResponse.isPending };
    },
    fetchQuery: useCallback(
      (pathAndInput, opts) => {
        const cacheKey = getCacheKey(pathAndInput);

        return queryClient.fetchQuery({
          queryKey: cacheKey,
          queryFn: () => (client as any).query(...getArgs(pathAndInput, opts)),
          ...opts,
        });
      },
      [client, queryClient],
    ),
    prefetchQuery: useCallback(
      (pathAndInput, opts) => {
        const cacheKey = getCacheKey(pathAndInput);

        return queryClient.prefetchQuery({
          queryKey: cacheKey,
          queryFn: () => (client as any).query(...getArgs(pathAndInput, opts)),
          ...opts,
        });
      },
      [client, queryClient],
    ),
    invalidateQuery: useCallback(
      (pathAndInput, filters) => {
        return queryClient.invalidateQueries({
          queryKey: getCacheKey(pathAndInput),
          ...filters,
        });
      },
      [queryClient],
    ),
    cancelQuery: useCallback(
      (pathAndInput) => {
        return queryClient.cancelQueries({
          queryKey: getCacheKey(pathAndInput) as any,
        });
      },
      [queryClient],
    ),
    setQueryData: useCallback(
      (pathAndInput, output) => {
        const cacheKey = getCacheKey(pathAndInput);

        queryClient.setQueryData(cacheKey, output);
      },
      [queryClient],
    ),
    setQueriesData: useCallback(
      (pathAndInput, output) => {
        const cacheKeyOrFilter = Array.isArray(pathAndInput) ? getCacheKey(pathAndInput) : pathAndInput;

        queryClient.setQueriesData(cacheKeyOrFilter as any, output);
      },
      [queryClient],
    ),
    setInfiniteQueryData: useCallback(
      (pathAndInput, output) => {
        const cacheKey = getCacheKey(pathAndInput);

        queryClient.setQueryData(cacheKey, output);
      },
      [queryClient],
    ),
    getQueryData: useCallback(
      (pathAndInput) => {
        const cacheKey = getCacheKey(pathAndInput);
        return queryClient.getQueryData(cacheKey);
      },
      [queryClient],
    ),
  };

  return {
    ...clientQueryHooks,
    // This is defined here to reuse our useMutation abstraction
    // and assign onError props using our own type that difers
    // from the react-query useMutation error.

    // NOTE: Ideally the onError here will catch all errors and handle them internally, meaning
    // we dont need the define an onError handler on the consumer - we cand just pass a defaultErrorMessage to be used onError.
    // However we still allow the consumer to handle the onError themselves for situations like optimistic updates.
    useErrorHandledMutation: (path, opts, defaultErrorMessage?: string) => {
      const notifications = useNotifications();

      return clientQueryHooks.useMutation(path, {
        ...opts,

        /**
         * ⚠️ @warning Consider using defaultErrorMessage parameter instead of onError for consistent error handling.
         * Example: useErrorHandledMutation('path', {}, 'Custom error message')
         * Only use onError if you need access perform actions like optimistic updates.
         */
        onError: (err, _input, _context) => {
          if (opts?.onError) {
            opts.onError(err, _input, _context);
            return;
          }

          // Check multiple paths for error information
          const statusCode =
            err.data?.httpStatus ||
            (err as any).originalError?.responseBody?.error?.json?.fetcherError?.fetcherResponse?.statusCode;

          if (statusCode && statusCode >= 400 && statusCode < 500) {
            notifications.add({
              type: 'error',
              title: getPreferredErrorMessage(err),
            });
            return;
          }

          // Handle all other errors (including 5XX) internally
          notifications.add({
            type: 'error',
            title: defaultErrorMessage ?? getPreferredErrorMessage(err),
          });
        },
      });
    },
  };
}
