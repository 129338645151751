import type { ServerError, StoreObject } from '@apollo/client';
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink, from } from '@apollo/client';
import type { KeyFieldsFunction } from '@apollo/client/cache/inmemory/policies';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import type {
  AnyAuthProviderContext,
  AuthProviderContext,
  AuthProviderProps,
  AuthSwapProviderContext,
  AuthSwapProviderOptions,
} from '@newfront-insurance/next-auth';
import type { Provider } from '@newfront-insurance/react-provision';
import { useProvider } from '@newfront-insurance/react-provision';
import type { ConfigType } from '@newfront-insurance/shared-public-config';
import { graphql as gqlTada, readFragment } from 'gql.tada';
import { useState, type PropsWithChildren } from 'react';

interface NewfrontApolloProviderProps extends PropsWithChildren {
  authProvider:
    | Provider<AuthSwapProviderContext, AuthSwapProviderOptions>
    | Provider<AuthProviderContext, AuthProviderProps>;
  config: ConfigType;
}

const dataIdFromObject: KeyFieldsFunction = (object: StoreObject) => {
  // Type-safe way to extract unique identifier
  if (object.uuid && typeof object.uuid === 'string') {
    return object.uuid;
  }
  // adding id as a fallback
  if (object.id && typeof object.id === 'string') {
    return object.id;
  }
  return false; // Fallback if no identifier found
};

const RETRYABLE_STATUS_CODES = [418, 502, 503, 504];

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 5,
    jitter: true,
  },
  attempts: (_count, _operation, error: ServerError) => {
    if (RETRYABLE_STATUS_CODES.includes(error.statusCode)) {
      return true;
    }

    return false;
  },
});

export function NewfrontApolloProvider({ children, authProvider, config }: NewfrontApolloProviderProps) {
  const { API } = config;
  const { getToken } = useProvider(authProvider as Provider<AnyAuthProviderContext>);

  const [httpLink] = useState(() =>
    createHttpLink({
      uri: API.GRAPHQL_BFF,
    }),
  );

  const [authLink] = useState(() => {
    return setContext(async (_, { headers }) => {
      const token = await getToken();

      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });
  });

  const [apolloClient] = useState(
    () =>
      new ApolloClient({
        link: from([authLink, retryLink, httpLink]),
        cache: new InMemoryCache({
          // This tells Apollo to use the object's ID for cache normalization
          dataIdFromObject,
        }),
      }),
  );

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

export const graphql = gqlTada;

export type { FragmentOf, ResultOf, VariablesOf } from 'gql.tada';
export { readFragment };
